import React from 'react'
import Scheduler from './index'
import CustomDialog from '../../../Product/components/organisms/Dialog'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button } from '@material-ui/core'
import { mapData } from '../../../Product/common/components'
import { useState } from 'react'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useContext } from 'react'
import authContext from '../../../Product/context/auth/authContext'
import alertContext from '../../../Product/context/alert/alertContext'
import schedulePickupContext from '../../../Product/context/plugin/schedulePickup/schedulePickupContext'
import { useEffect } from 'react'
import moment from 'moment'
import MultiCheckIn from './MultiCheckIn'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'

const MultiPickup = ({
    invoice,
    pickupData,
    auctionData,
    sellerData,
    trigger,
    setTrigger,
    sch_type,
    openSchedule,
    multipleInvoice,
    component,
    clearData,
    location_id,
    setLocalSelectedDate,
    scheduleType,
    requestReSchedule,
}) => {
    const [scheduleStep, setScheduleStep] = useState(multipleInvoice == 1 ? 2 : 0)
    const [scheduleModal, setScheduleModal] = useState(false)
    const [scheduleModal1, setScheduleModal1] = useState(false)
    const [rescheduleModal, setReScheduleModal] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [exist, setExist] = useState(
        typeof pickupData === 'object' && Object.keys(pickupData).length ? true : false,
    )
    const [bookingDates, setBookingDates] = useState({
        start_date: '',
        end_date: '',
    })
    const [allLocations, setAllLocations] = useState([])
    const [isDisable, setIsDisable] = useState({
        pickup: false,
        request: false,
    })
    const [loading, setLoading] = useState()
    const [timings, setTimings] = useState({
        default: [],
        scheduled: [],
    })
    const { user, loadUser } = useContext(authContext)
    const { setAlert } = useContext(alertContext)
    const {
        getAllTimings,
        updateDefaultTimings,
        addSchedule,
        responseStatus,
        all_timings,
        clearResponse,
    } = useContext(schedulePickupContext)

    const pickupValidationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'Special characters are not allowed')
            .max(20, '20 characters max')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email format')
            .max(250, '250 characters max')
            .required('Required'),
        phone: Yup.string()
            .trim()
            .matches(/^[+]?[0-9]+$/, 'Only numbers are allowed')
            .min(10, 'Invalid!')
            .max(15, 'Maximum 14 characters')
            .required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            name: `${user?.first_name} ${user?.last_name}`,
            email: user?.email,
            phone: user?.phone,
            location_id: auctionData?.location_id || location_id || allLocations[0]?.id,
            location_name: '',
            curbside: '',
            status: requestReSchedule ? 'extension' : '',
        },
        validationSchema: scheduleStep == 1 && pickupValidationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (scheduleStep == 0) {
                if (!values.location_id) {
                    return setAlert('Kindly Select Any One Location!', 'error')
                }
                updateDefaultTimings(
                    allLocations?.find((val) => val.id == values.location_id)?.range_key,
                )
            }
            if (multipleInvoice == 1) {
                if (scheduleStep == 2) {
                    if (!selectedTime) {
                        return setAlert('Kindly Select Time!', 'error')
                    }
                }
                if (scheduleStep != 4) {
                    return handleScheduleNext()
                }
            } else {
                if (scheduleStep != 2) {
                    return handleScheduleNext()
                }
            }
            if (!selectedTime) {
                return setAlert('Kindly Select Time!', 'error')
            }
            let sDate = new Date(`${selectedDate} ${selectedTime}`)
            const body = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                site_id: invoice?.site_id,
                status: requestReSchedule ? 'extension' : '',
                user_id: user?.id,
                invoice_id: invoice?.common_invoice,
                base_url: global?.storeDetails?.dmap_url?.includes('/search')
                    ? global?.storeDetails?.dmap_url?.replace('/search', '')
                    : global?.storeDetails?.dmap_url,
                booking_start_date: moment(sDate, 'YYYY-MM-DD HH:mm')
                    .utc()
                    .format('MM/DD/YYYY h:mm A'),
                booking_end_date: moment(sDate, 'YYYY-MM-DD HH:mm')
                    .add(
                        allLocations?.find((val) => val.id == values.location_id)?.range_key[0]
                            ?.pickup_minutes != 0
                            ? allLocations?.find((val) => val.id == values.location_id)
                                  ?.range_key[0]?.pickup_minutes
                            : 5,
                        'minutes',
                    )
                    .utc()
                    .format('MM/DD/YYYY h:mm A'),
                booking_loc_start_date: moment(sDate, 'YYYY-MM-DD HH:mm').format(
                    'MM/DD/YYYY h:mm A',
                ),
                requested_extension_date: moment(sDate, 'YYYY-MM-DD HH:mm')
                    .utc()
                    .format('MM/DD/YYYY h:mm A'),
                localTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
                seller_name: sellerData?.seller_name,
                seller_email: sellerData?.email,
                location_id: values.location_id,
                location_name: allLocations?.find((val) => val.id == values.location_id)
                    ?.range_key[0]?.business_name,
                curbside: values.curbside,
                total_count: allLocations?.find((val) => val.id == values.location_id)?.range_key[0]
                    ?.pickup_limit,
            }
            setLoading(true)
            addSchedule(body)
        },
    })

    useEffect(() => {
        setExist(typeof pickupData === 'object' && Object.keys(pickupData).length ? true : false)
        formik.setValues({
            name: pickupData?.name,
            email: pickupData?.email,
            phone: pickupData?.phone,
            location_id: pickupData?.location_id,
            curbside: pickupData?.curbside,
        })
    }, [pickupData])

    useEffect(() => {
        if (setLocalSelectedDate) {
            setLocalSelectedDate(selectedDate)
        }
    }, [selectedDate])

    const pickupInfo = [
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            placeholder: 'Enter your name',
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Email address',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Phone number',
            name: 'phone',
            type: 'text',
            placeholder: 'Enter your phone number',
            class: 'col-12',
            formik: formik,
        },
    ]

    const handleScheduleBack = () => {
        if (scheduleStep > 0) {
            setScheduleStep(scheduleStep - 1)
        }
    }

    const handleScheduleNext = () => {
        if (scheduleStep < 3) {
            if (scheduleStep == 0) {
                return setScheduleStep(scheduleStep + 2)
            }
            if (scheduleStep == 2) {
                return setScheduleStep(scheduleStep + 2)
            }
            setScheduleStep(scheduleStep + 1)
        }
    }

    const manageScheduleModal = () => {
        if (user?.allow_schedule == 1) {
            setScheduleModal1(!scheduleModal1)
        } else {
            setScheduleModal(!scheduleModal)
            setScheduleStep(multipleInvoice == 1 ? 2 : 0)
            formik.resetForm()
            formik.setTouched({})
        }
    }

    useEffect(() => {
        if (invoice?.site_id) {
            getAllTimings({
                site_id: invoice?.site_id,
                random_id: '',
                id: '',
            })
        }
    }, [invoice?.site_id])

    useEffect(() => {
        if (sch_type == 1 || sch_type == 3) {
            loadUser()
        }
    }, [sch_type])

    useEffect(() => {
        setAllLocations(all_timings.all_locations)
    }, [all_timings.all_locations])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addSchedule') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    handleScheduleNext()
                    setTimeout(() => {
                        setTrigger(!trigger)
                        manageScheduleModal()
                    }, 2000)
                }
                if (responseStatus.status === 'error') {
                    setAlert(responseStatus.message, 'error')
                }
            }
            clearResponse()
        }
    }, [responseStatus])

    useEffect(() => {
        if (openSchedule) {
            manageScheduleModal()
        }
    }, [openSchedule])

    useEffect(() => {
        if (allLocations.length)
            updateDefaultTimings(
                allLocations?.find((val) => val.id == formik.values.location_id)?.range_key,
            )
    }, [allLocations])

    useEffect(() => {
        setTimings({
            ...timings,
            default: all_timings?.default_timings,
            scheduled: all_timings?.scheduled_timings,
        })
    }, [all_timings?.default_timings, all_timings?.scheduled_timings])

    const addBusinessDays = (date, daysToAdd) => {
        let result = moment(date).local()
        let addedDays = 0
        while (addedDays < daysToAdd) {
            result.add(1, 'days')

            if (result.day() !== 0 && result.day() !== 1) {
                addedDays++
            }
        }
        return result
    }
    {
        console.log(pickupData, 'yyyyyyyyyyy')
    }

    useEffect(() => {
        const currentDate = moment() // Keep as Moment.js object for proper comparison
        const schdulepickupdate = moment.utc(pickupData?.sche_localpick_local).local()
        const pickupByDate = addBusinessDays(pickupData?.date_closed, 5)

        // const pickupDate = moment(schdulepickupdate) // Normalize to the start of the day

        console.log(
            pickupByDate.isBefore(currentDate, 'day'),
            schdulepickupdate,
            pickupByDate,
            pickupData.sche_localpick_local != null,
            pickupData.sche_localpick_local !== '',
            'yyy',
        )

        if (pickupByDate.isBefore(currentDate, 'day')) {
            console.log('Pickup date is in the past.')
            setIsDisable((prevState) => ({ ...prevState, request: false, pickup: true }))
        } else if (pickupByDate.isAfter(currentDate, 'day')) {
            console.log('Pickup date is in the future.')
            setIsDisable((prevState) => ({ ...prevState, pickup: false, request: true }))
        } else {
            console.log('Pickup date is today!')
            setIsDisable({ pickup: false, request: false }) // Enable both
        }
    }, [pickupData]) // Runs whenever pickupData changes

    return (
        <div>
            {sch_type == 2 && (
                <div className="d-flex align-items-center">
                    {global.storeConfigration?.hide_checkin?.value == 1 ? null : (
                        <MultiCheckIn
                            pickupData={invoice}
                            setTrigger={setTrigger}
                            trigger={trigger}
                            curbside={
                                all_timings?.all_locations?.find(
                                    (val) => val.id == invoice?.schedule_location_id,
                                )?.range_key[0]?.curbside
                            }
                        />
                    )}
                    {global.storeDetails?.theme == 13 ? (
                        <>
                            {exist ? (
                                <>
                                    {pickupData?.status === 'completed' ||
                                    pickupData?.approved_for_extension === 2 ? null : (
                                        <>
                                            {global?.storeConfigration?.reschedule_hours?.value ? (
                                                moment(
                                                    new Date(
                                                        moment
                                                            .utc(pickupData?.booking_date)
                                                            .local()
                                                            .format('YYYY-MM-DD h:mm A'),
                                                    ),
                                                ).diff(new Date(), 'hours') >=
                                                parseInt(
                                                    global?.storeConfigration?.reschedule_hours
                                                        ?.value,
                                                ) ? (
                                                    <div className="ml-3">
                                                        <PrimaryButton
                                                            btnSize={
                                                                isDisable.request
                                                                    ? 'd-none'
                                                                    : 'schedulePickupBtn mb-3 noPrint'
                                                            }
                                                            onClick={manageScheduleModal}
                                                            disabled={isDisable.request}
                                                        >
                                                            Request ReSchedule
                                                        </PrimaryButton>
                                                    </div>
                                                ) : null
                                            ) : (
                                                <div className="ml-3">
                                                    <PrimaryButton
                                                        btnSize={
                                                            isDisable.request
                                                                ? 'd-none'
                                                                : 'schedulePickupBtn mb-3 noPrint '
                                                        }
                                                        onClick={manageScheduleModal}
                                                        disabled={isDisable.request}
                                                    >
                                                        Request ReSchedule
                                                    </PrimaryButton>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : null}
                        </>
                    ) : null}
                </div>
            )}

            {sch_type == 1 && (
                <>
                    {scheduleType && (
                        <PrimaryButton
                            btnSize="schedulePickupBtn mt-3"
                            onClick={manageScheduleModal}
                            label="Schedule Pickup Appointment"
                            disabled={isDisable.pickup}
                        />
                    )}
                    {global.storeDetails?.theme == 13 && (
                        <>
                            {requestReSchedule && (
                                <PrimaryButton
                                    btnSize={
                                        isDisable.request ? 'd-none' : 'schedulePickupBtn mt-3'
                                    }
                                    onClick={manageScheduleModal}
                                    label="Request ReSchedule"
                                    disabled={isDisable.request}
                                />
                            )}
                        </>
                    )}
                </>
            )}
            <CustomDialog
                title={exist ? 'Reschedule Pickup' : 'Schedule Pickup'}
                open={scheduleModal}
                function={() => {
                    manageScheduleModal()
                    if (clearData) {
                        clearData()
                    }
                }}
                closeBtn={true}
                className="scheduleModal"
                maxWidth="md"
            >
                <div className="scheduleModalCnt">
                    {scheduleStep === 0 ? (
                        <div className="smDetails">
                            <div className="smTop">
                                <h5 className="smTitle">
                                    {exist ? 'Edit' : 'Choose'} Your Pickup Location
                                </h5>
                            </div>
                            <div className="smMain">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="container col-12">
                                            {(allLocations.find(
                                                (val) => val.id == auctionData?.location_id,
                                            )
                                                ? [
                                                      allLocations.find(
                                                          (val) =>
                                                              val.id == auctionData?.location_id,
                                                      ),
                                                  ]
                                                : allLocations
                                            )?.map((val, index) => (
                                                <div key={index}>
                                                    <div className="card-input border my-2 mx-0 p-3 d-flex align-items-start justify-content-between flex-wrap">
                                                        <div className="card-body border-0 p-0">
                                                            {val.range_key[0]?.business_name}
                                                            <address>
                                                                {
                                                                    val.range_key[0]
                                                                        ?.business_address1
                                                                }{' '}
                                                                {
                                                                    val.range_key[0]
                                                                        ?.business_address2
                                                                }
                                                                <br />
                                                                {
                                                                    val.range_key[0]?.business_state
                                                                }, {val.range_key[0]?.business_city}
                                                                , {val.range_key[0]?.business_zip}
                                                                <br />
                                                                {val.range_key[0]?.business_country}
                                                            </address>
                                                        </div>
                                                        <div className="cardFoot">
                                                            <PrimaryButton
                                                                label={
                                                                    exist
                                                                        ? 'ReSchedule an appointment'
                                                                        : 'Schedule an appointment'
                                                                }
                                                                type="submit"
                                                                onClick={() =>
                                                                    formik.setFieldValue(
                                                                        'location_id',
                                                                        val.id,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : scheduleStep === 1 ? (
                        <div className="smDetails">
                            <div className="smTop d-flex align-items-center justify-content-start">
                                <Button className="backBtn" onClick={handleScheduleBack}>
                                    <span className="material-icons">arrow_back</span>
                                </Button>
                                <h5 className="smTitle m-0">
                                    {exist ? 'Edit' : 'Enter'} Your Details
                                </h5>
                            </div>
                            <div className="smMain">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        {Object.values(mapData(pickupInfo))}
                                        <div className="col-12">
                                            <PrimaryButton label="Next" type="submit" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : scheduleStep === 2 ? (
                        <div className="smDate">
                            <div className="smTop d-flex align-items-center">
                                <Button className="backBtn mr-2" onClick={handleScheduleBack}>
                                    <span className="material-icons">arrow_back</span>
                                </Button>
                                <h5 className="smTitle m-0">Select Date & Time</h5>
                            </div>{' '}
                            <div className="smMain">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <Scheduler
                                                selectedDate={selectedDate}
                                                setSelectedDate={setSelectedDate}
                                                selectedTime={selectedTime}
                                                setSelectedTime={setSelectedTime}
                                                pickupData={pickupData}
                                                invoice={invoice}
                                                location_id={formik.values.location_id}
                                                bookingDates={bookingDates}
                                                setBookingDates={setBookingDates}
                                                requestReSchedule={requestReSchedule}
                                            />
                                        </div>
                                        {selectedDate && multipleInvoice !== 1 ? (
                                            <div className="col-12">
                                                <PrimaryButton
                                                    label={
                                                        loading
                                                            ? 'Loading'
                                                            : `${
                                                                  exist
                                                                      ? 'Reschedule Pickup'
                                                                      : 'Schedule Pickup'
                                                              }`
                                                    }
                                                    type="submit"
                                                />
                                            </div>
                                        ) : (
                                            <div className="col-12">
                                                <PrimaryButton label="Next" type="submit" />
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : scheduleStep === 4 ? (
                        <div className="smDate">
                            {multipleInvoice ? (
                                <div className="smMain booked text-center">
                                    <p>Choose Other Invoices</p>
                                    {component}
                                    <div className="col-12">
                                        <form onSubmit={formik.handleSubmit}>
                                            <PrimaryButton
                                                label={loading ? 'Loading' : 'Schedule Pickup'}
                                                type="submit"
                                            />
                                        </form>
                                    </div>
                                </div>
                            ) : (
                                <div className="smMain booked text-center">
                                    <span
                                        className="material-icons-outlined"
                                        style={{ fontSize: '55px' }}
                                    >
                                        download_done
                                    </span>
                                    <h4>Booking {exist ? 'Edited' : 'Confirmed'}!</h4>
                                </div>
                            )}
                        </div>
                    ) : scheduleStep === 5 ? (
                        <div className="smDate">
                            <div className="smMain booked text-center">
                                <span
                                    className="material-icons-outlined"
                                    style={{ fontSize: '55px' }}
                                >
                                    download_done
                                </span>
                                <h4>Booking Confirmed!</h4>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </CustomDialog>
            <CustomDialog
                title={'Schedule Pickup'}
                open={scheduleModal1}
                function={manageScheduleModal}
                className="scheduleModal blockedModal"
                maxWidth="md"
            >
                <div className="scheduleModalCnt">
                    <div className="smDetails">
                        <div className="smTop">
                            <h5 className="smTitle mb-4">
                                Your Schedule Pickup feature is blocked. Contact Admin for further
                                info.
                            </h5>
                        </div>
                        <div className="smMain">
                            <div className="cardFoot">
                                <PrimaryButton
                                    label="Close"
                                    type="submit"
                                    onClick={() => setScheduleModal1(!scheduleModal1)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </CustomDialog>
        </div>
    )
}

export default MultiPickup
