import React from 'react'
import Scheduler from './index'
import CustomDialog from '../../../Product/components/organisms/Dialog'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button } from '@material-ui/core'
import { mapData } from '../../../Product/common/components'
import { useState } from 'react'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useContext } from 'react'
import authContext from '../../../Product/context/auth/authContext'
import alertContext from '../../../Product/context/alert/alertContext'
import schedulePickupContext from '../../../Product/context/plugin/schedulePickup/schedulePickupContext'
import { useEffect } from 'react'
import moment from 'moment'
import { format } from 'date-fns'
import productContext from '../../../Product/context/product/productContext'
import MessagePickup from './Message'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import CheckIn from './CheckIn'
const Pickup = ({
    invoice,
    pickupData,
    auctionData,
    sellerData,
    trigger,
    setTrigger,
    multipleInvoice,
    pickup_status,
}) => {
    const [scheduleStep, setScheduleStep] = useState(multipleInvoice == 1 ? 2 : 0)
    const [checkInStep, setCheckInStep] = useState(0)
    const [scheduleModal, setScheduleModal] = useState(false)
    const [scheduleModal1, setScheduleModal1] = useState(false)
    const [checkInModal, setCheckInModal] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [allLocations, setAllLocations] = useState([])
    const [exist, setExist] = useState(
        typeof pickupData === 'object' && Object.keys(pickupData).length ? true : false,
    )
    const [loading, setLoading] = useState()
    const { user } = useContext(authContext)
    const { setAlert } = useContext(alertContext)
    const {
        getAllTimings,
        updateDefaultTimings,
        addSchedule,
        responseStatus,
        all_timings,
        clearResponse,
    } = useContext(schedulePickupContext)
    const pickupValidationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'Special characters are not allowed')
            .max(20, '20 characters max')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email format')
            .max(250, '250 characters max')
            .required('Required'),
        phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .min(12, 'Please Enter Phone Number With Country Code.')
            .max(16, 'Invalid phone number')
            .required('Required'),
    })

    const checkInValidationSchema = Yup.object().shape({
        parking_lot_no: Yup.string().when('type', {
            is: 2,
            then: Yup.string()
                .trim()
                .matches(/^[a-zA-Z]*$/g, 'Special characters are not allowed')
                .max(20, '20 characters max')
                .required('Required'),
        }),
        car_make: Yup.string()
            .trim()
            .matches(/^[a-zA-Z]*$/g, 'Special characters are not allowed')
            .max(20, '20 characters max')
            .required('Required'),
        model: Yup.string()
            .trim()
            .matches(/^[a-zA-Z]*$/g, 'Special characters are not allowed')
            .max(20, '20 characters max')
            .required('Required'),
        color: Yup.string()
            .trim()
            .matches(/^[a-zA-Z]*$/g, 'Special characters are not allowed')
            .max(20, '20 characters max')
            .required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            name: pickupData?.name || `${user?.first_name} ${user?.last_name}`,
            email: pickupData?.email || user?.email,
            phone: pickupData?.phone || user?.phone,
            location_id: pickupData?.location_id || auctionData?.location_id || allLocations[0]?.id,
            location_name: pickupData?.location_name || '',
            curbside: pickupData?.curbside || '',
        },
        validationSchema: scheduleStep == 1 && pickupValidationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (scheduleStep == 0) {
                if (!values.location_id) {
                    return setAlert('Kindly Select Any One Location!', 'error')
                }
                updateDefaultTimings(
                    allLocations?.find((val) => val.id == values.location_id)?.range_key,
                )
            }
            // if (all_timings?.default_timings?.[0]?.curbside?.length) {
            //     if (scheduleStep != 3) {
            //         if (scheduleStep == 2) {
            //             console.log(selectedTime)
            //             if (!selectedTime) {
            //                 return setAlert('Kindly Select Time!', 'error')
            //             }
            //         }
            //         return handleScheduleNext()
            //     }
            // } else {
            if (scheduleStep != 2) {
                return handleScheduleNext()
            }
            if (!selectedTime) {
                return setAlert('Kindly Select Time!', 'error')
            }
            // }
            // if (all_timings?.default_timings?.[0]?.curbside?.length) {
            //     if (!values.curbside) {
            //         return setAlert('Kindly Select Any One Curbside!', 'error')
            //     }
            // }
            let sDate = new Date(`${selectedDate} ${selectedTime}`)
            console.log(sDate, 'sDate')
            const body = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                site_id: invoice?.site_id,
                user_id: user?.id,
                invoice_id: invoice?.common_invoice,
                base_url: global?.storeDetails?.dmap_url?.includes('/search')
                    ? global?.storeDetails?.dmap_url?.replace('/search', '')
                    : global?.storeDetails?.dmap_url,
                booking_start_date: moment(sDate, 'YYYY-MM-DD HH:mm')
                    .utc()
                    .format('MM/DD/YYYY h:mm A'),
                booking_end_date: moment(sDate, 'YYYY-MM-DD HH:mm')
                    .add(
                        allLocations?.find((val) => val.id == values.location_id)?.range_key[0]
                            ?.pickup_minutes != 0
                            ? allLocations?.find((val) => val.id == values.location_id)
                                  ?.range_key[0]?.pickup_minutes
                            : 5,
                        'minutes',
                    )
                    .utc()
                    .format('MM/DD/YYYY h:mm A'),
                booking_loc_start_date: moment(sDate, 'YYYY-MM-DD HH:mm').format(
                    'MM/DD/YYYY h:mm A',
                ),
                localTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
                seller_name: sellerData?.seller_name,
                seller_email: sellerData?.email,
                location_id: values.location_id,
                location_name: allLocations?.find((val) => val.id == values.location_id)
                    ?.range_key[0]?.business_name,
                curbside: values.curbside,
                total_count: allLocations?.find((val) => val.id == values.location_id)?.range_key[0]
                    ?.pickup_limit,
            }
            setLoading(true)
            addSchedule(body)
        },
    })
    const formikCheckIn = useFormik({
        initialValues: {
            type: pickupData?.name || `${user?.first_name} ${user?.last_name}`,
            parking_lot_no: pickupData?.email || user?.email,
            car_make: pickupData?.phone || user?.phone,
            model: pickupData?.location_id || allLocations[0]?.id,
            color: pickupData?.location_name || '',
        },
        validationSchema: checkInValidationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoading(true)
            addcheckIn(values)
        },
    })

    const pickupInfo = [
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            placeholder: 'Enter your name',
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Email address',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Phone number',
            name: 'phone',
            type: 'text',
            placeholder: 'Enter your phone number',
            class: 'col-12',
            formik: formik,
        },
    ]
    const checkInInfo = [
        {
            title: '',
            type: 'radio',
            class: 'col-md-6 col-12',
            int: 1,
            item: [
                { id: 1, description: 'Office' },
                { id: 2, description: 'Parking Lot' },
            ],
            name: 'customfield7',
            formik: formikCheckIn,
        },
        {
            label: 'Parking Lot No',
            name: 'parking_lot_no',
            type: 'text',
            placeholder: 'Parking Lot No',
            class: 'col-12',
            formik: formikCheckIn,
        },
        {
            label: 'Car Make',
            name: 'car_make',
            type: 'text',
            placeholder: 'Car Make',
            class: 'col-12',
            formik: formikCheckIn,
        },
        {
            label: 'Model',
            name: 'model',
            type: 'text',
            placeholder: 'Model',
            class: 'col-12',
            formik: formikCheckIn,
        },
        {
            label: 'Color',
            name: 'color',
            type: 'text',
            placeholder: 'Color',
            class: 'col-12',
            formik: formikCheckIn,
        },
    ]

    const handleScheduleBack = () => {
        if (scheduleStep > 0) {
            setScheduleStep(scheduleStep - 1)
        }
    }

    const handleScheduleNext = () => {
        // if (all_timings?.default_timings?.[0]?.curbside?.length) {
        //     if (scheduleStep < 4) {
        //         setScheduleStep(scheduleStep + 1)
        //     }
        // } else {
        if (scheduleStep < 3) {
            if (scheduleStep == 2) {
                return setScheduleStep(scheduleStep + 2)
            }
            setScheduleStep(scheduleStep + 1)
        }
        // }
    }

    const manageScheduleModal = () => {
        if (user?.allow_schedule == 1) {
            setScheduleModal1(!scheduleModal1)
        } else {
            setScheduleModal(!scheduleModal)
            setScheduleStep(multipleInvoice == 1 ? 2 : 0)
            formik.resetForm()
            formik.setTouched({})
        }
    }
    const manageCheckInModal = () => {
        setCheckInModal(!checkInModal)
        setCheckInStep(0)
        formikCheckIn.resetForm()
        formikCheckIn.setTouched({})
    }
    useEffect(() => {
        if (invoice?.site_id) {
            getAllTimings({
                site_id: invoice?.site_id,
                random_id: '',
                id: '',
            })
        }
    }, [invoice])

    useEffect(() => {
        setAllLocations(all_timings.all_locations)
    }, [all_timings.all_locations])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addSchedule') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    handleScheduleNext()
                    setTimeout(() => {
                        setTrigger(!trigger)
                        manageScheduleModal()
                    }, 2000)
                }
                if (responseStatus.status === 'error') {
                    setAlert(responseStatus.message, 'error')
                }
            }
            clearResponse()
        }
    }, [responseStatus])
    // useEffect(() => {
    //     if (all_timings?.default_timings?.[0]?.curbside?.length) {
    //         if (scheduleStep === 4) {
    //             setTrigger(!trigger)
    //         }
    //     } else {
    //         if (scheduleStep === 3) {
    //             setTrigger(!trigger)
    //         }
    //     }
    // }, [scheduleStep])

    useEffect(() => {
        setExist(typeof pickupData === 'object' && Object.keys(pickupData).length ? true : false)
        formik.setValues({
            name: pickupData?.name,
            email: pickupData?.email,
            phone: pickupData?.phone,
            location_id: pickupData?.location_id,
            curbside: pickupData?.curbside,
        })
    }, [pickupData])
    useEffect(() => {
        if (allLocations.length && pickupData?.location_id) {
            updateDefaultTimings(
                allLocations?.find((val) => val.id == formik.values.location_id)?.range_key,
            )
        } else if (allLocations.length) {
            updateDefaultTimings(allLocations?.[0]?.range_key)
        }
    }, [allLocations, pickupData?.location_id])
    return (
        <div>
            {exist ? (
                // global.storeConfigration?.new_schedule_pickup_2?.value !== '1'
                true ? (
                    <>
                        {pickupData?.status === 'completed' ? null : (
                            <>
                                {user?.allow_schedule == 1 ? null : (
                                    <>
                                        {global.storeConfigration?.hide_checkin?.value ==
                                        1 ? null : (
                                            <CheckIn
                                                pickupData={pickupData}
                                                setTrigger={setTrigger}
                                                trigger={trigger}
                                                curbside={
                                                    all_timings?.all_locations?.find(
                                                        (val) => val.id == pickupData?.location_id,
                                                    )?.range_key[0]?.curbside
                                                }
                                            />
                                        )}
                                    </>
                                )}
                                {console.log(
                                    moment(
                                        new Date(
                                            moment
                                                .utc(pickupData?.booking_date)
                                                .local()
                                                .format('YYYY-MM-DD h:mm A'),
                                        ),
                                    ).diff(new Date(), 'hours'),
                                    'moment(new Date(pickupData?.booking_date))',
                                )}
                                {global.storeDetails?.theme == 18 ? null : (
                                    <>
                                        {global?.storeConfigration?.reschedule_hours?.value ? (
                                            moment(
                                                new Date(
                                                    moment
                                                        .utc(pickupData?.booking_date)
                                                        .local()
                                                        .format('YYYY-MM-DD h:mm A'),
                                                ),
                                            ).diff(new Date(), 'hours') >=
                                            parseInt(
                                                global?.storeConfigration?.reschedule_hours?.value,
                                            ) ? (
                                                <PrimaryButton
                                                    btnSize="schedulePickupBtn mb-3 noPrint"
                                                    onClick={manageScheduleModal}
                                                >
                                                    <span className="material-icons-outlined mr-2">
                                                        event
                                                    </span>
                                                    Reschedule
                                                </PrimaryButton>
                                            ) : null
                                        ) : (
                                            <PrimaryButton
                                                btnSize="schedulePickupBtn mb-3 noPrint"
                                                onClick={manageScheduleModal}
                                            >
                                                <span className="material-icons-outlined mr-2">
                                                    event
                                                </span>
                                                Reschedule
                                            </PrimaryButton>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {/* <MessagePickup
                            product={invoice}
                            pickupData={pickupData}
                            sellerData={sellerData}
                        /> */}
                        <div className="card">
                            <div className="card-header">
                                <h4 className="text-center my-1">
                                    Your Booking Confirmed!{' '}
                                    {pickupData?.status ? (
                                        <strong>
                                            (
                                            {pickup_status ||
                                                (global.storeDetails?.theme === 19
                                                    ? pickupData?.status === 'handover'
                                                        ? 'Run Order'
                                                        : pickupData?.status
                                                    : pickupData?.status)}
                                            )
                                        </strong>
                                    ) : (
                                        ''
                                    )}
                                </h4>
                            </div>
                            <div className="card-body">
                                <AddToCalendarButton
                                    name={`${invoice.common_invoice} (Pickup)`}
                                    options={[
                                        'Apple',
                                        'Google',
                                        'iCal',
                                        'Microsoft365',
                                        'MicrosoftTeams',
                                        'Outlook.com',
                                        'Yahoo',
                                    ]}
                                    location={`${
                                        allLocations?.find(
                                            (val) => val.id == pickupData?.location_id,
                                        )?.range_key[0]?.business_name
                                    }${pickupData?.curbside ? ` - ${pickupData?.curbside}` : ''}`}
                                    startDate={moment
                                        .utc(pickupData?.booking_date)
                                        .local()
                                        .format('YYYY-MM-DD')}
                                    endDate={moment
                                        .utc(pickupData?.booking_end_date)
                                        .local()
                                        .format('YYYY-MM-DD')}
                                    startTime={moment
                                        .utc(pickupData?.booking_date)
                                        .local()
                                        .format('h:mm A')}
                                    endTime={moment
                                        .utc(pickupData?.booking_end_date)
                                        .local()
                                        .format('h:mm A')}
                                    timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                                    hideBackground={true}
                                    buttonStyle={'date'}
                                />
                                <div className="mt-4">
                                    <div className="row">
                                        <div className="form-group col-md">
                                            <label className="form-label">Date</label>
                                            <p>
                                                {moment
                                                    .utc(pickupData?.booking_date)
                                                    .local()
                                                    .format('dddd, MMM Do [at] h:mm')}{' '}
                                                -{' '}
                                                {moment
                                                    .utc(pickupData?.booking_end_date)
                                                    .local()
                                                    .format('h:mm A')}
                                            </p>
                                        </div>
                                        <div className="form-group col-md">
                                            <label className="form-label">Name</label>
                                            <p>{pickupData?.name}</p>
                                        </div>
                                        <div className="form-group col-md">
                                            <label className="form-label">Email</label>
                                            <p>{pickupData?.email}</p>
                                        </div>
                                        <div className="form-group col-md">
                                            <label className="form-label">Phone</label>
                                            <p>{pickupData?.phone}</p>
                                        </div>
                                        <div className="form-group col-md">
                                            <label className="form-label">Pickup Address</label>
                                            {(allLocations.find(
                                                (val) => val.id == formik.values.location_id,
                                            )
                                                ? [
                                                      allLocations.find(
                                                          (val) =>
                                                              val.id == formik.values.location_id,
                                                      ),
                                                  ]
                                                : allLocations
                                            )?.map((val) => (
                                                <address>
                                                    {val.range_key[0]?.business_address1}{' '}
                                                    {val.range_key[0]?.business_address2}
                                                    <br />
                                                    {`${val.range_key[0]?.business_state}, ${val.range_key[0]?.business_city}, ${val.range_key[0]?.business_zip}`}
                                                    <br />
                                                    {val.range_key[0]?.business_country}
                                                </address>
                                            ))}
                                            {/* <address>
                                                {auctionData?.address}
                                                <br />
                                                {auctionData?.city}
                                                <br />
                                                {auctionData?.state}, {auctionData?.country},{' '}
                                                {auctionData?.zipcode}
                                            </address> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )
            ) : global?.storeConfigration?.new_schedule_pickup_1?.value == 1 &&
              global?.storeConfigration?.new_schedule_pickup?.value != 1 ? null : (
                <PrimaryButton
                    btnSize="schedulePickupBtn mb-2 noPrint"
                    onClick={manageScheduleModal}
                >
                    <span className="material-icons mr-2">local_shipping</span>
                    Schedule Pickup
                </PrimaryButton>
            )}
            <CustomDialog
                title={exist ? 'Reschedule Pickup' : 'Schedule Pickup'}
                open={scheduleModal}
                function={manageScheduleModal}
                className="scheduleModal"
                closeBtn={true}
                maxWidth="md"
            >
                <div className="scheduleModalCnt">
                    {scheduleStep === 0 ? (
                        <div className="smDetails">
                            <div className="smTop">
                                <h5 className="smTitle">
                                    {exist ? 'Edit' : 'Choose'} Your Pickup Location
                                </h5>
                            </div>
                            <div className="smMain">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="container col-12">
                                            {(allLocations.find(
                                                (val) => val.id == auctionData?.location_id,
                                            )
                                                ? [
                                                      allLocations.find(
                                                          (val) =>
                                                              val.id == auctionData?.location_id,
                                                      ),
                                                  ]
                                                : allLocations
                                            )?.map((val, index) => (
                                                <div key={index}>
                                                    {/* <input
                                                        type="radio"
                                                        name="product"
                                                        className="card-input-element"
                                                        value={val.id}
                                                        checked={
                                                            formik.values.location_id == val.id
                                                        }
                                                        onChange={(e) => {
                                                            formik.setFieldValue(
                                                                'location_id',
                                                                e.target.value,
                                                            )
                                                        }}
                                                    /> */}
                                                    <div className="card-input border my-2 mx-0 p-3 d-flex align-items-start justify-content-between flex-wrap">
                                                        <div className="card-body border-0 p-0">
                                                            {val.range_key[0]?.business_name}
                                                            <address>
                                                                {
                                                                    val.range_key[0]
                                                                        ?.business_address1
                                                                }{' '}
                                                                {
                                                                    val.range_key[0]
                                                                        ?.business_address2
                                                                }
                                                                <br />
                                                                {`${val.range_key[0]?.business_state}, ${val.range_key[0]?.business_city}, ${val.range_key[0]?.business_zip}`}
                                                                <br />
                                                                {val.range_key[0]?.business_country}
                                                            </address>
                                                        </div>
                                                        <div className="cardFoot">
                                                            <PrimaryButton
                                                                label="Schedule an appointment"
                                                                type="submit"
                                                                onClick={() =>
                                                                    formik.setFieldValue(
                                                                        'location_id',
                                                                        val.id,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : scheduleStep === 1 ? (
                        <div className="smDetails">
                            <div className="smTop d-flex align-items-center justify-content-start">
                                <Button className="backBtn" onClick={handleScheduleBack}>
                                    <span className="material-icons">arrow_back</span>
                                </Button>
                                <h5 className="smTitle m-0">
                                    {exist ? 'Edit' : 'Enter'} Your Details
                                </h5>
                            </div>
                            <div className="smMain">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        {Object.values(mapData(pickupInfo))}
                                        <div className="col-12">
                                            <PrimaryButton label="Next" type="submit" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : scheduleStep === 2 ? (
                        <div className="smDate">
                            <div className="smTop d-flex align-items-center">
                                <Button className="backBtn mr-2" onClick={handleScheduleBack}>
                                    <span className="material-icons">arrow_back</span>
                                </Button>
                                <h5 className="smTitle m-0">Select Date & Time</h5>
                            </div>{' '}
                            <div className="smMain">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <Scheduler
                                                selectedDate={selectedDate}
                                                setSelectedDate={setSelectedDate}
                                                selectedTime={selectedTime}
                                                setSelectedTime={setSelectedTime}
                                                pickupData={pickupData}
                                                invoice={invoice}
                                            />
                                        </div>
                                        {selectedDate ? (
                                            <div className="col-12">
                                                <PrimaryButton
                                                    label={
                                                        loading
                                                            ? 'Loading'
                                                            : `${
                                                                  exist
                                                                      ? 'Reschedule Pickup'
                                                                      : 'Schedule Pickup'
                                                              }`
                                                    }
                                                    type="submit"
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : scheduleStep === 3 ? (
                        <div className="smDetails">
                            <div className="smTop d-flex align-items-center">
                                <Button className="backBtn" onClick={handleScheduleBack}>
                                    <span className="material-icons">arrow_back</span>
                                </Button>
                                <h5 className="smTitle mb-0">
                                    {exist ? 'Edit' : 'Choose'} Your Curbside Location
                                </h5>
                            </div>
                            <div className="smMain">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="container mt-3">
                                            <div className="row">
                                                {all_timings?.default_timings?.[0]?.curbside?.map(
                                                    (val, index) => (
                                                        <div className="col-md-2 mb-2" key={index}>
                                                            <div className="position-relative">
                                                                <input
                                                                    type="radio"
                                                                    name="product"
                                                                    className="cardRadioInput"
                                                                    value={val.name}
                                                                    checked={
                                                                        formik.values.curbside ==
                                                                        val.name
                                                                    }
                                                                    onChange={(e) =>
                                                                        formik.setFieldValue(
                                                                            'curbside',
                                                                            e.target.value,
                                                                        )
                                                                    }
                                                                />
                                                                <div className="card m-0 card-default card-input">
                                                                    <div className="card-header text-center">
                                                                        {val.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ),
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <PrimaryButton
                                                label={
                                                    loading
                                                        ? 'Loading'
                                                        : `${
                                                              exist
                                                                  ? 'Reschedule Pickup'
                                                                  : 'Schedule Pickup'
                                                          }`
                                                }
                                                type="submit"
                                                disabled={loading}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : scheduleStep === 4 ? (
                        <div className="smDate">
                            <div className="smMain booked text-center">
                                <span
                                    className="material-icons-outlined"
                                    style={{ fontSize: '55px' }}
                                >
                                    download_done
                                </span>
                                {/* <img src="/assets/images/confirm.png" /> */}
                                <h4>Booking {exist ? 'Edited' : 'Confirmed'}!</h4>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </CustomDialog>
            <CustomDialog
                title={'Schedule Pickup'}
                open={scheduleModal1}
                function={manageScheduleModal}
                className="scheduleModal"
                maxWidth="md"
            >
                <div className="scheduleModalCnt">
                    <div className="smDetails">
                        <div className="smTop">
                            <h5 className="smTitle">
                                Your Schedule Pickup feature is blocked. Contact Admin for further
                                info.
                            </h5>
                        </div>
                        <div className="smMain">
                            <div className="cardFoot">
                                <PrimaryButton
                                    label="Close"
                                    type="submit"
                                    onClick={() => setScheduleModal1(!scheduleModal1)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </CustomDialog>
        </div>
    )
}

export default Pickup
